import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { XappOnboarderService } from '../services/xapp-onboarder/xapp-onboarder.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { NotificationService } from '../services/ui/notification.service';

interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'rd-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss']
})
export class SimulatorComponent implements OnInit {
  @ViewChild('f') myNgForm;
  form: FormGroup;
  form1: FormGroup;
  e2ConfigurationChecked = false;

  features = [
    { feature: 'ES', enableE2Sim: false },
    { feature: 'TS', enableE2Sim: false },
    { feature: 'QOS', enableE2Sim: false }
  ];

  simData: { feature: string, enableE2Sim: boolean };
  esValue: boolean = false;
  tsValue: any;
  qosValue: boolean = false;
  tsPortion: boolean = false;
  selectedNumber: number;
  dropdownValues: number[] = [];

  constructor(
    private fb: FormBuilder,
    private fb1: FormBuilder,

    private xappOnboarderService: XappOnboarderService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {

    const controls = {};
    this.features.forEach(feature => {
      controls[feature.feature] = [feature.enableE2Sim];
    });

    this.form = this.fb.group(controls);
    this.form = this.fb.group({

      "IP": ['', []],
      "PORT": ['', []],
      // "amfId": ['', []],
      "plmnId": ['', []],
      "gnbId": ['', []],
      "E2AP": ['', []],
      "E2SM_KPM": ['', []],
      "E2SM_RC": ['', []],
      "e2NodeConfiguration": ['', []],
      "cellCount": ['', []],
      "ueCount": ['', []],
      "servingCell": ['', []],
      "anomalsCell": ['', []],
      "servingCellRSRP": ['', []],
      "anomalsUlThroughPut": ['', []],
      "servingCellRSRQ": ['', []],
      "anomalsDlThroughPut": ['', []],
    });

    this.getContainerData()

    this.form1 = this.fb1.group({
      feature: ['', []],
      anonymousData: ['', []],
      nonAnonymousData: ['', []],
      jsonData: ['', []],
    });

  }

  selectFeature1(value: any) {
    if (value === "TS") {
      this.tsPortion = true;
    }
    else if (value === "ES" || value === "QOS") {
      this.tsPortion = false
    }
  }

  selectFeature(value: any) {
    if (value === "ES") {
      this.esValue = true;
      this.tsValue = false;
      this.qosValue = false;
    }
    else if (value === "TS") {
      this.tsValue = true;
      this.esValue = false;
      this.qosValue = false;
    }
    else if (value === "QOS") {
      this.qosValue = true;
      this.tsValue = false;
      this.esValue = false;
    }
  }

  getContainerData() {
    this.xappOnboarderService.getContainerStatus().subscribe({
      next: (res: any) => {
        let data = JSON.parse(res);
        let Original = Object.entries(data);
        let mainArray2: any = [];
        for (let i = 0; i < Original.length; i++) {
          mainArray2.push(Original[i][1])
        }
        const newData = mainArray2.flat();
        newData.map(x => {
          if (x.container_name.includes("r4-e2simulator-es")) {
            this.form.get("ES")?.setValue(true);
          }
          if (x.container_name.includes("r4-e2simulator-ts")) {
            this.form.get("TS")?.setValue(true);
          }
          if (x.container_name.includes("r4-e2simulator-qos")) {
            this.form.get("QOS")?.setValue(true);
          }
        });
      },
      error: console.log,
    })
  }

  // This function will be called when a toggle is switched
  onToggleChange(featureName: string, event: any) {
    const enabled = event.target.checked;  // true or false
    this.simData = {
      feature: featureName,
      enableE2Sim: enabled
    };
    console.log('Data to send:', this.simData);  // For testing, replace this with your API call
  }

  startSimulator() {
    const data = { ...this.form.value, ...this.simData }
    console.log(data, "sdfghjklertyuioxcvbnm,rtyuikol");

    if (data) {
      this.xappOnboarderService.startSimulator(data)
        .subscribe({
          next: (response: string) => {
            this.notificationService.success(response);
            this.myNgForm.resetForm();
            console.log('Success:', response);
          },
          error: (error) => {
            this.notificationService.warn("Simulator has failed");
            this.myNgForm.resetForm();
            console.error('Error:', error);
          }
        }
        );
    }
  }

  submitData() {
    if (this.form1.controls['feature'].value === "TS") {
      // const data ={
      //   "feature": this.form1.controls['feature'].value,
      //   "anonymousData": this.form1.controls['anonymousData'].value,
      //   "nonAnonymousData": this.form1.controls['nonAnonymousData'].value
      // }
      const formData = this.getFormData();
      console.log(formData, "data");

      this.xappOnboarderService.addAnomousData(formData)
        .subscribe({
          next: (response: any) => {
            this.notificationService.success(response);
            this.form1.reset({ feature: 'TS' });
            console.log('Success:', response);
          },
          error: (error) => {
            this.notificationService.warn(error);
            this.form1.reset({ feature: 'TS' });
            console.error('Error:', error);
          }
        })
    }
    else if (this.form1.controls['feature'].value === "ES") {
      console.log("'feature' :", this.form1.controls['feature'].value, "'jsonData'", this.form1.controls['jsonData'].value);
      this.esValue = false;
      this.tsValue = false;
      this.qosValue = false;
    }
    else if (this.fb1.control['feature'].value === "QOS") {
      console.log(this.form1.value);
      this.esValue = false;
      this.tsValue = false;
      this.qosValue = false;
    }

  }

  getFormData() {
    const formValues = this.form1.value;
    const filteredData = {};

    // Loop through the form values and only include non-empty fields
    Object.keys(formValues).forEach((key) => {
      if (formValues[key]) {
        filteredData[key] = formValues[key];
      }
    });

    return filteredData;
    
  }

  updateDropdown() {
    // const num = this.form1.get('cellCount')?.value;
    const num = this.selectedNumber;
    if (num >= 1 && num <= 9) {
      this.dropdownValues = Array.from({ length: num }, (_, i) => i + 1);
    } else {
      this.dropdownValues = []; // Clear dropdown if input is invalid
    }
  }

}
