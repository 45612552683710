/*-
 * ========================LICENSE_START=================================
 * O-RAN-SC
 * %%
 * Copyright (C) 2019 AT&T Intellectual Property
 * %%
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================LICENSE_END===================================
 */

import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthGuardServiceService } from '../auth-guard/auth-guard-service.service';
import { AppConfigServiceService } from '../appconfig/app-config-service.service';
@Injectable({
  providedIn: 'root'
})
export class XappOnboarderService {
  private gnbinfo = new BehaviorSubject<any>({});
  private apiUrl = 'http://localhost:4000';
  data: object = {};
  token: any;

  //subject 

  private chartDataSubject = new BehaviorSubject<any>([]);
  chartData$ = this.chartDataSubject.asObservable();
  private postDataSubject = new BehaviorSubject<any>(null);
  postData$ = this.postDataSubject.asObservable();


  //automatically refresh the chart list 
  private _refreshrequired = new Subject<void>();
  get Refreshrequired() {
    return this._refreshrequired;

  }

  updateChartData(newData: any) {
    this.chartDataSubject.next(newData);
  }

  updatePostData(data: any) {
    this.postDataSubject.next(data);
  }

  private component = 'xappobrd';
  private appConfig;
  dynamicUrl: any;

  constructor(
    private httpClient: HttpClient,
    private http: HttpClient,
    public authService: AuthGuardServiceService,
    private environment: AppConfigServiceService,
    private injector: Injector
  ) { }

  get getInfoData(): Observable<any> {
    return this.gnbinfo.asObservable();
  }

  // Setter for the subject
  setData(value: any): void {
    this.gnbinfo.next(value);
  }

  headers = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Authorization": localStorage.getItem('id_token')
    }),
  };

  login(id: any): Observable<any> {
    return this.http.post("http://35.238.94.32:32080/e2mgr/authenticate/login", id, { observe: 'response' });
  }

  onboardXappFile(data: any): Observable<any> {
    console.log(data, "data");
    return this.httpClient.post(environment.dynamicUrl + '/onboard/api/v1/register', data).pipe(
      tap(() => {
        this.Refreshrequired.next();
      })
    );;
  }

  getonboardXappFile(): Observable<any> {
    return this.httpClient.get(environment.dynamicUrl + '/onboard/api/v1/charts')
  }

  deployXappFile(data: any): Observable<any> {
    return this.httpClient.post(environment.dynamicUrl + '/appmgr/ric/v1/xapps', data);
  }

  deleteXappFile(data: any): Observable<any> {
    console.log(data, "delete");
    return this.httpClient.delete(environment.dynamicUrl + '/delete/api/charts/' + data.xappName + "/" + data.HelmVersion).pipe(
      tap(() => {
        this.Refreshrequired.next();
      })
    );;;
  }

  undeployXappFile(data: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const options = {
      headers,
      body: data
    };

    return this.httpClient.delete(environment.dynamicUrl + '/onboard/api/v1/unregister', options).pipe(
      tap(() => {
        this.Refreshrequired.next();
      })
    );;;
  }

  controlChartXappFile(): Observable<any> {
    return this.httpClient.get(environment.dynamicUrl + '/backend/pod/api/xapp-status');
  }

  getAlarmDefinitions(): Observable<any> {
    return this.httpClient.get(environment.dynamicUrl + '/alarm/ric/v1/alarms/define');
  }

  getAlarm(): Observable<any> {
    return this.httpClient.get(environment.dynamicUrl + '/backend/api/alarms/active');
                          // https://devnearrtric.vvdnric.com/backend/api/alarms/active
  }

  getGnb(): Observable<any> {
    return this.httpClient.get(environment.dynamicUrl + '/e2mgr/v1/nodeb/states');
  }

  // getGnbInfo(): Observable<any> {
  //   return this.httpClient.get(environment.dynamicUrl + '/e2mgr/v1/nodeb/gnb_734_373_16b8cef1');
  // }

  getUe(): Observable<any> {
    return this.httpClient.get(environment.dynamicUrl + '/backend/ric/v1/api/uelist');
  }

  getCell(): Observable<any> {
    return this.httpClient.get(environment.dynamicUrl + '/backend/ric/v1/api/celllist');
  }

  getInventoryName(res: any): Observable<any> {
    return this.httpClient.get(environment.dynamicUrl + '/backend/kpi' + "/" + res);
  }

  getKpmData(id: string): Observable<any> {
    return this.httpClient.get(environment.dynamicUrl + '/backend/api/gnb/fetch-measurment' + '/' + id);
  }

  getGenInfo(id: string): Observable<any> {
    return this.httpClient.get(environment.dynamicUrl + '/backend/api/gnb/ran-function' + '/' + id);
  }

  getSubscriptionData(): Observable<any> {
    return this.httpClient.get(environment.dynamicUrl + '/backend/apis/subscription');
  }

  gnbDetails(id: string): Observable<any> {
    return this.httpClient.get(environment.dynamicUrl + '/backend/api/gnb/info' + '/' + id);
  }

  updateToggleButton(payload: any) {
    return this.httpClient.put(environment.dynamicUrl + '/backend/api/gnb/measurement', payload)
  }

  deleteSubscriptionData(id: any) {
    return this.httpClient.delete(environment.dynamicUrl + '/backend/api/subscriptions' + '/' + id);
  }

  getGnbConnectedInventoryNames() {
    return this.httpClient.get(environment.dynamicUrl + '/backend/gnb/connected-inventory-names');
  }

  postCreateSubscription(data: any): Observable<any> {
    const urldata = environment.dynamicUrl + '/backend/apis/randata' + '/' + data.node + '/' + data.property
    return this.httpClient.post(urldata, null)
  }

  getContainerStatus() {
    return this.httpClient.get(environment.dynamicUrl + '/backend/pod/api/container-status');
  }

  getUeIdCount() {
    return this.httpClient.get(environment.dynamicUrl + '/backend/gnb/ue/count')
  }

  getCellIdCount() {
    return this.httpClient.get(environment.dynamicUrl + '/backend/gnb/cell/count')
  }

  getQosData() {
    return this.httpClient.get(environment.dynamicUrl + '/backend/api/qos/gbr')
  }

  onSubmitpostEsConfig(data: any): Observable<any> {
    return this.httpClient.post(environment.dynamicUrl + '/backend/api/cell/set-values', data).pipe(
      tap(() => {
        this.Refreshrequired.next();
      })
    );;
  }

  getEsDashboard() {
    return this.httpClient.get(environment.dynamicUrl + '/backend/api/cell-status')
  }

  startSimulator(data: any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.httpClient.post(environment.dynamicUrl + '/backend/api/v1/scale/run', data, { headers, responseType: 'text' })
  }

  addAnomousData(data: any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.httpClient.post(environment.dynamicUrl + '/backend/api/send-feature', data, { headers, responseType: 'text' })
  }

}
