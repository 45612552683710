<div class="head">Common Configuration</div>
<form [formGroup]="form" (ngSubmit)="startSimulator()">
  <div class="outset">
    <div class="row">
      <span class="col-4">
        <mat-form-field>
          <mat-label>IP</mat-label>
          <input matInput formControlName="IP">
          <mat-hint>(Ex-"E2T")</mat-hint>
        </mat-form-field>
      </span>
      <span class="col-4">
        <mat-form-field>
          <mat-label>PORT</mat-label>
          <input matInput formControlName="PORT">
          <mat-hint>(Ex- "E2 Terminator port")</mat-hint>
        </mat-form-field>
      </span>
      <span class="col-4">
        <mat-form-field>
          <mat-label>PLMN ID</mat-label>
          <input matInput formControlName="plmnId">
          <mat-hint>(Ex- "00F110" (plminid 6 bits long))</mat-hint>
        </mat-form-field>
      </span>
    </div>
    <div class="row">
      <span class="col-4">
        <mat-form-field>
          <mat-label>GNB ID</mat-label>
          <input matInput formControlName="gnbId">
          <mat-hint>(Ex- "16B8CEF1" (gnbid 10bits long))</mat-hint>
        </mat-form-field>
      </span>
    </div>
    <div>
      <span class="row-6 labels">Protocol Versions</span>
    </div>
    <div class="row">
      <span class="col-4">
        <mat-form-field>
          <mat-label>E2AP</mat-label>
          <mat-select formControlName="E2AP">
            <mat-option value="V1">V1</mat-option>
            <mat-option value="V2">V2</mat-option>
            <mat-option value="V3">V3</mat-option>
          </mat-select> </mat-form-field>
      </span>
      <span class="col-4">
        <mat-form-field>
          <mat-label>E2SM_KPM</mat-label>
          <mat-select formControlName="E2SM_KPM">
            <mat-option value="V1">V1</mat-option>
            <mat-option value="V2">V2</mat-option>
            <mat-option value="V3">V3</mat-option>
          </mat-select> </mat-form-field>
      </span>
      <span class="col-4">
        <mat-form-field>
          <mat-label>E2SM_RC</mat-label>
          <mat-select formControlName="E2SM_RC">
            <mat-option value="V1">V1</mat-option>
            <mat-option value="V2">V2</mat-option>
            <mat-option value="V3">V3</mat-option>
          </mat-select> </mat-form-field>
      </span>
    </div>
    <div>
      <span class="row-6 labels">E2 Setup</span>
    </div>
    <div>
      <span><mat-form-field>
          <mat-label>Select Feature</mat-label>
          <mat-select (selectionChange)="selectFeature1($event.value)">
            <mat-option value="ES">ES</mat-option>
            <mat-option value="TS">TS</mat-option>
            <mat-option value="QOS">QOS</mat-option>
          </mat-select>
        </mat-form-field>
      </span>
    </div>
    <div>
      <mat-checkbox class="example-margin" [(ngModel)]="e2ConfigurationChecked" formControlName="e2NodeConfiguration">E2
        Node Configuration Update
        Allow</mat-checkbox>
    </div>
  </div>

  <div class="head" *ngIf="tsPortion">TS Configuration</div>
  <div class="outset" *ngIf="tsPortion">
    <div class="row">
      <span class="col-6 labelFix ml_10">Cell Configuration</span>
      <span class="col-6 labelFix">UE Configuration</span>
    </div>
    <div class="row">
      <span class="col-6">
        <mat-form-field>
          <mat-label>Cell Count</mat-label>
          <input matInput formControlName="cellCount" [(ngModel)]="selectedNumber" (input)="updateDropdown()" min="1" max="9">
          <mat-hint>(select only 1-9 numbers)</mat-hint>
        </mat-form-field>
      </span>
      <span class="col-6">
        <mat-form-field>
          <mat-label>UE Count</mat-label>
          <input matInput formControlName="ueCount">
        </mat-form-field>
      </span>
    </div>
    <div class="row">
      <span class="col-3 labelFix ml_10">Serving Cell</span>
      <span class="col-3"><mat-form-field>
          <mat-label>Select Serving Cell</mat-label>
          <mat-select formControlName="servingCell" >
            <mat-option *ngFor="let num of dropdownValues" [value]="num">{{ num }}</mat-option>
          </mat-select>
        </mat-form-field>
      </span>
      <span class="col-3 labelFix ">Anomolous Cell</span>
      <span class="col-3"><mat-form-field>
          <mat-label>Select Anomolous Cell</mat-label>
          <mat-select formControlName="anomalsCell">
            <!-- <mat-option value="ES">1</mat-option> -->
            <mat-option *ngFor="let num of dropdownValues" [value]="num">{{ num }}</mat-option>

          </mat-select>
        </mat-form-field>
      </span>
    </div>
    <div class="row">
      <span class="col-6">
        <mat-form-field>
          <mat-label>RSRP</mat-label>
          <input matInput formControlName="servingCellRSRP">
        </mat-form-field>
      </span>
      <span class="col-6">
        <mat-form-field>
          <mat-label>UL Through Put</mat-label>
          <input matInput formControlName="anomalsUlThroughPut">
        </mat-form-field>
      </span>
    </div>
    <div class="row">
      <span class="col-6">
        <mat-form-field>
          <mat-label>RSRQ</mat-label>
          <input matInput formControlName="servingCellRSRQ">
        </mat-form-field>
      </span>
      <span class="col-6">
        <mat-form-field>
          <mat-label>DL Through Put</mat-label>
          <input matInput formControlName="anomalsDlThroughPut">
        </mat-form-field>
      </span>
    </div>
  </div>

  <div class="head">Simulator ON/OFF</div>
  <div class="catalog-container outset" style="margin-bottom: 15px;">
    <!-- <form class="row" [formGroup]="form" (ngSubmit)="startSimulator()"> -->
    <table class="app-control-table width-100 tableRan">
      <tr>
        <th class="thRan">Feature</th>
        <th class="thRan">Enable e2 Sim</th>
      </tr>
      <tr *ngFor="let data of features">
        <td class="tdRan">{{ data.feature }}</td>
        <td class="tdRan">
          <div class="custom-radio">
            <label class="switch">
              <input type="checkbox" [formControlName]="data.feature" (change)="onToggleChange(data.feature, $event)">
              <span class="slider round"></span>
            </label>
          </div>
        </td>
      </tr>
    </table>
    <div class="form-actions">
      <button mat-raised-button color="primary">Submit</button>
    </div>
  </div>
</form>

<span class="head">If you want to add anomalously data :</span>
<div class="outset">
  <form [formGroup]="form1" (ngSubmit)="submitData()" class="row">
    <span class="col-3"><mat-form-field>
        <mat-label>Select Feature</mat-label>
        <mat-select formControlName="feature" (selectionChange)="selectFeature($event.value)">
          <mat-option value="ES">ES</mat-option>
          <mat-option value="TS">TS</mat-option>
          <mat-option value="QOS">QOS</mat-option>
        </mat-select>
      </mat-form-field>
    </span>
    <span class="col-3" *ngIf="tsValue"><mat-form-field>
        <mat-label>Select Anomalous data</mat-label>
        <mat-select formControlName="anonymousData">
          <mat-option value="anomalous 1">Anomalous 1</mat-option>
          <mat-option value="anomalous 2">Anomalous 2</mat-option>
          <mat-option value="anomalous 3">Anomalous 3</mat-option>
        </mat-select>
      </mat-form-field>
    </span>
    <span class="col-3" *ngIf="tsValue"><mat-form-field>
        <mat-label>Select Non-anomalous data</mat-label>
        <mat-select formControlName="nonAnonymousData">
          <mat-option value="Non-anomalous 1">Non-anomalous 1</mat-option>
          <mat-option value="Non-anomalous 2">Non-anomalous 2</mat-option>
          <mat-option value="Non-anomalous 3">Non-anomalous 3</mat-option>
        </mat-select>
      </mat-form-field>
    </span>
    <span class="col-3">
      <mat-form-field class="example-full-width">
        <mat-label>Add JSON</mat-label>
        <textarea matInput rows="1" placeholder="{ 'UE-count': 5 }" formControlName="jsonData">{"UE-count": 5}</textarea>
      </mat-form-field>
    </span>

    <span class="col-3 txtAlnEnd">
      <button mat-raised-button color="primary">Add</button>
    </span>
  </form>
</div>